// Customizable Area Start
import React, { useState, useEffect } from "react";
import "../assets/UpdateProfile.css";
import { LoadingOutlined, PlusOutlined} from "@ant-design/icons";
import { Row, Col, Upload, message, Avatar, Select } from "antd";
import { toast } from "react-toastify";
import { ListenerUpdateBackground, ListenerShade, listenerimageupload, plusRed } from './assets';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()
import { Grid } from "@material-ui/core";
import { Formik, Form } from "formik";
import { useHistory, } from "react-router-dom";
import axios from "axios";
import * as Yup from "yup";
import { openNotification } from "../../../components/src/Notification.web";
import { nxzloader } from "../../dashboard/src/assets";
const { Option } = Select;
const urlConfig = require("../../../framework/src/config")
const baseUrl = urlConfig.baseURL;

const ListenerProfile = () => {
  const history = useHistory();
  const [genre, setGenre] = useState([])
  const [vibes, setVibes] = useState([])
  const [profile, setProfile] = useState<any>([])
  const [allGenre, setAllGenre] = useState<any>([])
  const [allVibes, setAllVibes] = useState<any>([])
  const [allVibesId, setAllVibesId] = useState<any>([])
  const [allGenreID, setAllGenreId] = useState<any>([])
  const [finishStatus, setfinishStatus] = useState(false);
  const [coverImgURL, setCoverImgURL] = useState("");
  const [city, setCity] = useState<any>()
  const [country, setCountry] = useState("");


  const session_id: any = localStorage.getItem("session_id")

  function beforeUpload(file: any) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }


    return isJpgOrPng;
  }
  const token: any = localStorage.getItem("token")
  useEffect(() => {
    if (token) {
      getGenre()
      getVibes()
      getProfile()
    } else {
      // history.push("/home");
    }
  }, []);
  useEffect(() => {
    UpdateSubsriptionPlan(session_id)
    UpdateDefaultCardUpdate()
    checkAmountSplit()
  }, [])

  const checkAmountSplit = async() =>{
    await axios
      .get(`${baseUrl}/bx_block_audiomusic/revenue_airs/split_royalty_list`, {
        headers: {
          token: token,
        }
      })
      .then((res) => {
         if(!res.data.data.creative.is_distributed){
          selectPlanAmountListeners(res.data.data.subscription_plan_id)
         }
      })
  }

  const selectPlanAmountListeners = async (subscription_plan_id: any) =>{
    await axios
      .get(`${baseUrl}/bx_block_subscription_plan/subscription_plans`, {
        headers: {
          token: token,
        }
      })
      .then((res) => {
        const subsciptionAmount = res.data.filter((plan: any) => subscription_plan_id == plan.attributes.subscription_plan_id)
         if(!res.data.data.creative.is_distributed && subsciptionAmount){
          SplitSubscriptionAmount(subsciptionAmount)
         }
      })
  }

  const UpdateSubsriptionPlan = async (val: any) => {
    await axios
      .put(`${baseUrl}/bx_block_subscription_plan/update_subscription?cs_id=${val}`, null, {
        headers: {
          token: token,
        }
      })
      .then((res) => {
      })
      .catch((err) => {
        console.log("error in UpdateSubsriptionPlan", err)
      });
  }

  const SplitSubscriptionAmount = async (amount: any) => {
    
    await axios
      .post(`${baseUrl}/bx_block_audiomusic/revenue_airs/split_royalty?amount=${amount}`,null, {
        headers: {
          token: token,
        }
      })
  }

  const UpdateDefaultCardUpdate = async () => {
    await axios
      .get(`${baseUrl}/bx_block_subscription_plan/set_default_card`, {
        headers: {
          token: token,
        }
      })
      .then((res) => {
      })
      .catch((err) => {
        console.log("error in UpdateSubsriptionPlan", err)
      });
  }
  const onBackButtonEvent = (e: any) => {
    e.preventDefault();
    if (!finishStatus) {
      if (true || window.location.href.includes("/ListenerProfile")) {
        alert("You cannot GO BACK")
        setfinishStatus(true)
        // your logic
        history.push('/ListenerProfile');
      } else {
        // window.history.pushState(null, "{}", window.location.pathname);
        setfinishStatus(false)
      }
    }
  }


  useEffect(() => {
    window.onhashchange = function () {
      console.log("back button click")
    }
    window.history.pushState(null, "{}", window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, []);

  const removeProfile = async () => {

    await axios.delete(`${baseUrl}/bx_block_profile/remove_profile_image`, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",

        token: token,
      }
    })
      .then((response) => {
        setProfileImageURL("");
        openNotification(response.data.errors[0].profile, 'Success')
      }).catch((err) => {
        openNotification(err.response.data.errors[0].profile, 'Error')

      });
  }

  const getGenre = async () => {
    await axios.get(`${baseUrl}/bx_block_music_types/genres`, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",

        token: token,
      }
    })
      .then((response) => {
        setGenre(response?.data?.data)
      }).catch((err) => {
        console.log(err);
      });
  }
  const getVibes = async () => {
    await axios.get(`${baseUrl}/bx_block_music_types/vibes`, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",

        token: token,
      }
    })
      .then((response) => {
        setVibes(response?.data?.data)
      }).catch((err) => {
        console.log(err);
      });
  }
  const [currentUserName, setCurrentUserName] = useState<any>("")
  const [loading, setLoading] = useState(false);
  const [profileImageURL, setProfileImageURL] = useState("");

  const getProfile = async () => {
    setLoading(true);
    await axios.get(`${baseUrl}/bx_block_profile/profile`, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",

        token: token,
      }
    })
      .then((response) => {
        setProfile(response?.data?.data)
        setCurrentUserName(response?.data?.data?.attributes?.user_name)
        setCountry(response?.data?.data?.attributes?.country)
        setCity(response?.data?.data?.attributes?.city)
      }).catch((err) => {
        // console.log(err);
      }).finally(() => {
        setLoading(false);
      })
  }
  function getBase64(video: any, callback: any) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(video);
  }
  const uploadImage = async (options: any, value: any) => {
    setLoading(true);
    const { onSuccess, onError, file, onProgress } = options;

    const fmData = new FormData();
    const config = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",

        "token": token
      },
    };
    if (value === 'remove_profile') {
      fmData.append('data[avatar]', '');
    } else {
      if (value === 'coverImage') {
        getBase64(file, (imageUrl: any) =>
        (
          setCoverImgURL(imageUrl))

        );
        fmData.append('data[cover_image]', file);
      } else {
        getBase64(file, (imageUrl: any) =>
        (
          setProfileImageURL(imageUrl))

        );
        fmData.append('data[avatar]', file);
      }
    }
    try {
      await axios.put(
        `${baseUrl}/bx_block_profile/profile`,
        fmData,
        config
      ).then((res) => {
        getProfile();
        setProfile(res?.data?.data)
        // toast.success('Profile Picture set Successfully', { position: toast.POSITION.BOTTOM_RIGHT })
        if (value === 'coverImage') {
          openNotification("Cover Photo Set Successfully", "Success")
        } else {
          openNotification("Profile Picture Set Successfully", 'Success')
        }
      }).catch((err) => {

      }).finally(() => {
        // openNotification("Profile Picture set Successfully", 'success')
        setLoading(false);
      })


    } catch (err) {
      console.log("Eroor: ", err);
      const error = new Error("Some error");
      onError({ err })

    }
  };
  const fileUploadHandler = async (info: any) => {
    let files = info.file
    // eslint-disable-next-line no-restricted-syntax
    for (const file of files) {
      console.log(files, "filesfilesfiles");

      const [name, file_type] = file.name.split(/(\.[^.]+)$/gm);

      let formData = new FormData();    //formdata object
      console.log(formData, "formData");
      formData.append('data[avatar]', file);


      uploadProfilePicture(formData)
    }
  };
  const uploadProfilePicture = async (value: any) => {
    await axios
      .put(`${baseUrl}/bx_block_profile/profile`, value, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",

          "token": token,
        },
      })
      .then((res) => {
        toast.success('Profile Picture set Successfully', { position: toast.POSITION.BOTTOM_RIGHT })
        getProfile()
      })
      .catch((err) => {
        console.log(err, "ppppppppppppppp");

      })
  }


  const iphoneRedirection = () => {
    window.location.href = `nxzsound://SignInScreen`;
    setTimeout(function () {
      history.push("/home");
      openNotification('Profile Created Successfully', 'Success')
    }, 4000);
  }

  const doExplore = async (value: any) => {
    if (profileImageURL !== "") {
      const data = {
        "data": {
          "vibes": value.vibes,
          "genres": value.generes,
          "user_name": value.userName,
          "country": value.country,
          "city": value.city
        }
      }
      await axios
        .put(`${baseUrl}/bx_block_profile/profile/update_genre`, data, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",

            "token": token,
          },
        })
        .then((res) => {
          // toast.success('Profile Created Successfully', { position: toast.POSITION.BOTTOM_RIGHT })
          const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;
          if (/iPhone/.test(userAgent) && !(window as any).MSStream) {
            iphoneRedirection()
          } else {
            history.push("/home");
            openNotification('Profile Created Successfully', 'Success')
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
    }
    else if (profileImageURL === '') {

      openNotification("Please Upload Profile Image", "Error")
    } else {
      ""
    }

  }
  const selectGenre = (id: any, setFieldValue: any, data: any) => {
    //@ts-ignore
    if ((!allGenre?.some(e => e === data))) {
      const genreData = [...allGenre, data]
      document.getElementById(data)?.setAttribute('style', 'background-color:#3131d7')
      //@ts-ignore
      setAllGenre(allGenre => [...allGenre, data])

      const genreObj = {
        "genre_id": id
      }
      //@ts-ignore
      setAllGenreId(allGenreID => [...allGenreID, genreObj])
      //@ts-ignore
      let text = genreData.join(',  ')
      // @ts-ignore
      let str = text.replaceAll(',', '  ');
      setFieldValue('generes', text)
    } else {
      for (let i = 0; i < allGenre.length; i++) {

        if (allGenre[i] === data) {

          allGenre.splice(i, 1);
        }

      }
      for (let i = 0; i < allGenreID.length; i++) {

        if (allGenreID[i].genre_id === id) {
          allGenreID.splice(i, 1);
        }

      }
      document.getElementById(data)?.setAttribute('style', 'background-color:rgb(48, 48, 48)')
      //@ts-ignore
      let text = allGenre.join()
      let str = text.replaceAll(',', '  ');
      setFieldValue('generes', str)
    }
  }
  const selectVIbes = (id: any, setFieldValue: any, data: any) => {
    //@ts-ignore
    if ((!allVibes?.some(e => e === data))) {
      const vibeData = [...allVibes, data]
      document.getElementById(data)?.setAttribute('style', 'background-color:#3131d7')
      //@ts-ignore
      setAllVibes(allVibes => [...allVibes, data])

      const vibesId = {
        "vibe_id": id
      }
      //@ts-ignore
      setAllVibesId(allVibesId => [...allVibesId, vibesId])
      //@ts-ignore
      let text = vibeData.join(',  ')
      // @ts-ignore
      let str = text.replaceAll(',', '  ');
      setFieldValue('vibes', text)
    } else {
      for (let i = 0; i < allVibes.length; i++) {

        if (allVibes[i] === data) {

          allVibes.splice(i, 1);
        }
        for (let i = 0; i < allVibesId.length; i++) {

          if (allVibesId[i].vibe_id === id) {
            allVibesId.splice(i, 1);
          }

        }
      }
      document.getElementById(data)?.setAttribute('style', 'background-color:rgb(48, 48, 48)')
      //@ts-ignore
      let text = allVibes.join()
      let str = text.replaceAll(',', '  ');
      setFieldValue('vibes', str)
    }
  }


  const uploadButton = (
    <div>
      {/* @ts-ignore */}
      {loading ? <LoadingOutlined translate  /> : <PlusOutlined translate />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  function arrayRemove(arr: any, value: any) {

    return arr.filter(function (ele: any) {
      return ele != value;
    });
  }

  const countries = [
    { code: 'AD', label: 'Andorra', phone: '376' },
    { code: 'AE', label: 'United Arab Emirates', phone: '971' },
    { code: 'AF', label: 'Afghanistan', phone: '93' },
    { code: 'AG', label: 'Antigua and Barbuda', phone: '1-268' },
    { code: 'AI', label: 'Anguilla', phone: '1-264' },
    { code: 'AL', label: 'Albania', phone: '355' },
    { code: 'AM', label: 'Armenia', phone: '374' },
    { code: 'AO', label: 'Angola', phone: '244' },
    { code: 'AQ', label: 'Antarctica', phone: '672' },
    { code: 'AR', label: 'Argentina', phone: '54' },
    { code: 'AS', label: 'American Samoa', phone: '1-684' },
    { code: 'AT', label: 'Austria', phone: '43' },
    { code: 'AU', label: 'Australia', phone: '61', suggested: true },
    { code: 'AW', label: 'Aruba', phone: '297' },
    { code: 'AX', label: 'Alland Islands', phone: '358' },
    { code: 'AZ', label: 'Azerbaijan', phone: '994' },
    { code: 'BA', label: 'Bosnia and Herzegovina', phone: '387' },
    { code: 'BB', label: 'Barbados', phone: '1-246' },
    { code: 'BD', label: 'Bangladesh', phone: '880' },
    { code: 'BE', label: 'Belgium', phone: '32' },
    { code: 'BF', label: 'Burkina Faso', phone: '226' },
    { code: 'BG', label: 'Bulgaria', phone: '359' },
    { code: 'BH', label: 'Bahrain', phone: '973' },
    { code: 'BI', label: 'Burundi', phone: '257' },
    { code: 'BJ', label: 'Benin', phone: '229' },
    { code: 'BL', label: 'Saint Barthelemy', phone: '590' },
    { code: 'BM', label: 'Bermuda', phone: '1-441' },
    { code: 'BN', label: 'Brunei Darussalam', phone: '673' },
    { code: 'BO', label: 'Bolivia', phone: '591' },
    { code: 'BR', label: 'Brazil', phone: '55' },
    { code: 'BS', label: 'Bahamas', phone: '1-242' },
    { code: 'BT', label: 'Bhutan', phone: '975' },
    { code: 'BV', label: 'Bouvet Island', phone: '47' },
    { code: 'BW', label: 'Botswana', phone: '267' },
    { code: 'BY', label: 'Belarus', phone: '375' },
    { code: 'BZ', label: 'Belize', phone: '501' },
    { code: 'CA', label: 'Canada', phone: '1', suggested: true },
    { code: 'CC', label: 'Cocos (Keeling) Islands', phone: '61' },
    { code: 'CD', label: 'Congo, Democratic Republic of the', phone: '243' },
    { code: 'CF', label: 'Central African Republic', phone: '236' },
    { code: 'CG', label: 'Congo, Republic of the', phone: '242' },
    { code: 'CH', label: 'Switzerland', phone: '41' },
    { code: 'CI', label: "Cote d'Ivoire", phone: '225' },
    { code: 'CK', label: 'Cook Islands', phone: '682' },
    { code: 'CL', label: 'Chile', phone: '56' },
    { code: 'CM', label: 'Cameroon', phone: '237' },
    { code: 'CN', label: 'China', phone: '86' },
    { code: 'CO', label: 'Colombia', phone: '57' },
    { code: 'CR', label: 'Costa Rica', phone: '506' },
    { code: 'CU', label: 'Cuba', phone: '53' },
    { code: 'CV', label: 'Cape Verde', phone: '238' },
    { code: 'CW', label: 'Curacao', phone: '599' },
    { code: 'CX', label: 'Christmas Island', phone: '61' },
    { code: 'CY', label: 'Cyprus', phone: '357' },
    { code: 'CZ', label: 'Czech Republic', phone: '420' },
    { code: 'DE', label: 'Germany', phone: '49', suggested: true },
    { code: 'DJ', label: 'Djibouti', phone: '253' },
    { code: 'DK', label: 'Denmark', phone: '45' },
    { code: 'DM', label: 'Dominica', phone: '1-767' },
    { code: 'DO', label: 'Dominican Republic', phone: '1-809' },
    { code: 'DZ', label: 'Algeria', phone: '213' },
    { code: 'EC', label: 'Ecuador', phone: '593' },
    { code: 'EE', label: 'Estonia', phone: '372' },
    { code: 'EG', label: 'Egypt', phone: '20' },
    { code: 'EH', label: 'Western Sahara', phone: '212' },
    { code: 'ER', label: 'Eritrea', phone: '291' },
    { code: 'ES', label: 'Spain', phone: '34' },
    { code: 'ET', label: 'Ethiopia', phone: '251' },
    { code: 'FI', label: 'Finland', phone: '358' },
    { code: 'FJ', label: 'Fiji', phone: '679' },
    { code: 'FK', label: 'Falkland Islands (Malvinas)', phone: '500' },
    { code: 'FM', label: 'Micronesia, Federated States of', phone: '691' },
    { code: 'FO', label: 'Faroe Islands', phone: '298' },
    { code: 'FR', label: 'France', phone: '33', suggested: true },
    { code: 'GA', label: 'Gabon', phone: '241' },
    { code: 'GB', label: 'United Kingdom', phone: '44' },
    { code: 'GD', label: 'Grenada', phone: '1-473' },
    { code: 'GE', label: 'Georgia', phone: '995' },
    { code: 'GF', label: 'French Guiana', phone: '594' },
    { code: 'GG', label: 'Guernsey', phone: '44' },
    { code: 'GH', label: 'Ghana', phone: '233' },
    { code: 'GI', label: 'Gibraltar', phone: '350' },
    { code: 'GL', label: 'Greenland', phone: '299' },
    { code: 'GM', label: 'Gambia', phone: '220' },
    { code: 'GN', label: 'Guinea', phone: '224' },
    { code: 'GP', label: 'Guadeloupe', phone: '590' },
    { code: 'GQ', label: 'Equatorial Guinea', phone: '240' },
    { code: 'GR', label: 'Greece', phone: '30' },
    { code: 'GS', label: 'South Georgia and the South Sandwich Islands', phone: '500' },
    { code: 'GT', label: 'Guatemala', phone: '502' },
    { code: 'GU', label: 'Guam', phone: '1-671' },
    { code: 'GW', label: 'Guinea-Bissau', phone: '245' },
    { code: 'GY', label: 'Guyana', phone: '592' },
    { code: 'HK', label: 'Hong Kong', phone: '852' },
    { code: 'HM', label: 'Heard Island and McDonald Islands', phone: '672' },
    { code: 'HN', label: 'Honduras', phone: '504' },
    { code: 'HR', label: 'Croatia', phone: '385' },
    { code: 'HT', label: 'Haiti', phone: '509' },
    { code: 'HU', label: 'Hungary', phone: '36' },
    { code: 'ID', label: 'Indonesia', phone: '62' },
    { code: 'IE', label: 'Ireland', phone: '353' },
    { code: 'IL', label: 'Israel', phone: '972' },
    { code: 'IM', label: 'Isle of Man', phone: '44' },
    { code: 'IN', label: 'India', phone: '91' },
    { code: 'IO', label: 'British Indian Ocean Territory', phone: '246' },
    { code: 'IQ', label: 'Iraq', phone: '964' },
    { code: 'IR', label: 'Iran, Islamic Republic of', phone: '98' },
    { code: 'IS', label: 'Iceland', phone: '354' },
    { code: 'IT', label: 'Italy', phone: '39' },
    { code: 'JE', label: 'Jersey', phone: '44' },
    { code: 'JM', label: 'Jamaica', phone: '1-876' },
    { code: 'JO', label: 'Jordan', phone: '962' },
    { code: 'JP', label: 'Japan', phone: '81', suggested: true },
    { code: 'KE', label: 'Kenya', phone: '254' },
    { code: 'KG', label: 'Kyrgyzstan', phone: '996' },
    { code: 'KH', label: 'Cambodia', phone: '855' },
    { code: 'KI', label: 'Kiribati', phone: '686' },
    { code: 'KM', label: 'Comoros', phone: '269' },
    { code: 'KN', label: 'Saint Kitts and Nevis', phone: '1-869' },
    { code: 'KP', label: "Korea, Democratic People's Republic of", phone: '850' },
    { code: 'KR', label: 'Korea, Republic of', phone: '82' },
    { code: 'KW', label: 'Kuwait', phone: '965' },
    { code: 'KY', label: 'Cayman Islands', phone: '1-345' },
    { code: 'KZ', label: 'Kazakhstan', phone: '7' },
    { code: 'LA', label: "Lao People's Democratic Republic", phone: '856' },
    { code: 'LB', label: 'Lebanon', phone: '961' },
    { code: 'LC', label: 'Saint Lucia', phone: '1-758' },
    { code: 'LI', label: 'Liechtenstein', phone: '423' },
    { code: 'LK', label: 'Sri Lanka', phone: '94' },
    { code: 'LR', label: 'Liberia', phone: '231' },
    { code: 'LS', label: 'Lesotho', phone: '266' },
    { code: 'LT', label: 'Lithuania', phone: '370' },
    { code: 'LU', label: 'Luxembourg', phone: '352' },
    { code: 'LV', label: 'Latvia', phone: '371' },
    { code: 'LY', label: 'Libya', phone: '218' },
    { code: 'MA', label: 'Morocco', phone: '212' },
    { code: 'MC', label: 'Monaco', phone: '377' },
    { code: 'MD', label: 'Moldova, Republic of', phone: '373' },
    { code: 'ME', label: 'Montenegro', phone: '382' },
    { code: 'MF', label: 'Saint Martin (French part)', phone: '590' },
    { code: 'MG', label: 'Madagascar', phone: '261' },
    { code: 'MH', label: 'Marshall Islands', phone: '692' },
    { code: 'MK', label: 'Macedonia, the Former Yugoslav Republic of', phone: '389' },
    { code: 'ML', label: 'Mali', phone: '223' },
    { code: 'MM', label: 'Myanmar', phone: '95' },
    { code: 'MN', label: 'Mongolia', phone: '976' },
    { code: 'MO', label: 'Macao', phone: '853' },
    { code: 'MP', label: 'Northern Mariana Islands', phone: '1-670' },
    { code: 'MQ', label: 'Martinique', phone: '596' },
    { code: 'MR', label: 'Mauritania', phone: '222' },
    { code: 'MS', label: 'Montserrat', phone: '1-664' },
    { code: 'MT', label: 'Malta', phone: '356' },
    { code: 'MU', label: 'Mauritius', phone: '230' },
    { code: 'MV', label: 'Maldives', phone: '960' },
    { code: 'MW', label: 'Malawi', phone: '265' },
    { code: 'MX', label: 'Mexico', phone: '52' },
    { code: 'MY', label: 'Malaysia', phone: '60' },
    { code: 'MZ', label: 'Mozambique', phone: '258' },
    { code: 'NA', label: 'Namibia', phone: '264' },
    { code: 'NC', label: 'New Caledonia', phone: '687' },
    { code: 'NE', label: 'Niger', phone: '227' },
    { code: 'NF', label: 'Norfolk Island', phone: '672' },
    { code: 'NG', label: 'Nigeria', phone: '234' },
    { code: 'NI', label: 'Nicaragua', phone: '505' },
    { code: 'NL', label: 'Netherlands', phone: '31' },
    { code: 'NO', label: 'Norway', phone: '47' },
    { code: 'NP', label: 'Nepal', phone: '977' },
    { code: 'NR', label: 'Nauru', phone: '674' },
    { code: 'NU', label: 'Niue', phone: '683' },
    { code: 'NZ', label: 'New Zealand', phone: '64' },
    { code: 'OM', label: 'Oman', phone: '968' },
    { code: 'PA', label: 'Panama', phone: '507' },
    { code: 'PE', label: 'Peru', phone: '51' },
    { code: 'PF', label: 'French Polynesia', phone: '689' },
    { code: 'PG', label: 'Papua New Guinea', phone: '675' },
    { code: 'PH', label: 'Philippines', phone: '63' },
    { code: 'PK', label: 'Pakistan', phone: '92' },
    { code: 'PL', label: 'Poland', phone: '48' },
    { code: 'PM', label: 'Saint Pierre and Miquelon', phone: '508' },
    { code: 'PN', label: 'Pitcairn', phone: '870' },
    { code: 'PR', label: 'Puerto Rico', phone: '1' },
    { code: 'PS', label: 'Palestine, State of', phone: '970' },
    { code: 'PT', label: 'Portugal', phone: '351' },
    { code: 'PW', label: 'Palau', phone: '680' },
    { code: 'PY', label: 'Paraguay', phone: '595' },
    { code: 'QA', label: 'Qatar', phone: '974' },
    { code: 'RE', label: 'Reunion', phone: '262' },
    { code: 'RO', label: 'Romania', phone: '40' },
    { code: 'RS', label: 'Serbia', phone: '381' },
    { code: 'RU', label: 'Russian Federation', phone: '7' },
    { code: 'RW', label: 'Rwanda', phone: '250' },
    { code: 'SA', label: 'Saudi Arabia', phone: '966' },
    { code: 'SB', label: 'Solomon Islands', phone: '677' },
    { code: 'SC', label: 'Seychelles', phone: '248' },
    { code: 'SD', label: 'Sudan', phone: '249' },
    { code: 'SE', label: 'Sweden', phone: '46' },
    { code: 'SG', label: 'Singapore', phone: '65' },
    { code: 'SH', label: 'Saint Helena', phone: '290' },
    { code: 'SI', label: 'Slovenia', phone: '386' },
    { code: 'SJ', label: 'Svalbard and Jan Mayen', phone: '47' },
    { code: 'SK', label: 'Slovakia', phone: '421' },
    { code: 'SL', label: 'Sierra Leone', phone: '232' },
    { code: 'SM', label: 'San Marino', phone: '378' },
    { code: 'SN', label: 'Senegal', phone: '221' },
    { code: 'SO', label: 'Somalia', phone: '252' },
    { code: 'SR', label: 'Suriname', phone: '597' },
    { code: 'SS', label: 'South Sudan', phone: '211' },
    { code: 'ST', label: 'Sao Tome and Principe', phone: '239' },
    { code: 'SV', label: 'El Salvador', phone: '503' },
    { code: 'SX', label: 'Sint Maarten (Dutch part)', phone: '1-721' },
    { code: 'SY', label: 'Syrian Arab Republic', phone: '963' },
    { code: 'SZ', label: 'Swaziland', phone: '268' },
    { code: 'TC', label: 'Turks and Caicos Islands', phone: '1-649' },
    { code: 'TD', label: 'Chad', phone: '235' },
    { code: 'TF', label: 'French Southern Territories', phone: '262' },
    { code: 'TG', label: 'Togo', phone: '228' },
    { code: 'TH', label: 'Thailand', phone: '66' },
    { code: 'TJ', label: 'Tajikistan', phone: '992' },
    { code: 'TK', label: 'Tokelau', phone: '690' },
    { code: 'TL', label: 'Timor-Leste', phone: '670' },
    { code: 'TM', label: 'Turkmenistan', phone: '993' },
    { code: 'TN', label: 'Tunisia', phone: '216' },
    { code: 'TO', label: 'Tonga', phone: '676' },
    { code: 'TR', label: 'Turkey', phone: '90' },
    { code: 'TT', label: 'Trinidad and Tobago', phone: '1-868' },
    { code: 'TV', label: 'Tuvalu', phone: '688' },
    { code: 'TW', label: 'Taiwan, Province of China', phone: '886' },
    { code: 'TZ', label: 'United Republic of Tanzania', phone: '255' },
    { code: 'UA', label: 'Ukraine', phone: '380' },
    { code: 'UG', label: 'Uganda', phone: '256' },
    { code: 'US', label: 'United States', phone: '1', suggested: true },
    { code: 'UY', label: 'Uruguay', phone: '598' },
    { code: 'UZ', label: 'Uzbekistan', phone: '998' },
    { code: 'VA', label: 'Holy See (Vatican City State)', phone: '379' },
    { code: 'VC', label: 'Saint Vincent and the Grenadines', phone: '1-784' },
    { code: 'VE', label: 'Venezuela', phone: '58' },
    { code: 'VG', label: 'British Virgin Islands', phone: '1-284' },
    { code: 'VI', label: 'US Virgin Islands', phone: '1-340' },
    { code: 'VN', label: 'Vietnam', phone: '84' },
    { code: 'VU', label: 'Vanuatu', phone: '678' },
    { code: 'WF', label: 'Wallis and Futuna', phone: '681' },
    { code: 'WS', label: 'Samoa', phone: '685' },
    { code: 'XK', label: 'Kosovo', phone: '383' },
    { code: 'YE', label: 'Yemen', phone: '967' },
    { code: 'YT', label: 'Mayotte', phone: '262' },
    { code: 'ZA', label: 'South Africa', phone: '27' },
    { code: 'ZM', label: 'Zambia', phone: '260' },
    { code: 'ZW', label: 'Zimbabwe', phone: '263' },
  ];

  const ExploreSchema = Yup.object().shape({
    generes: Yup.string()
      .required('Please Select Genres.'),
    vibes: Yup.string()
      .required('Please Select Vibes.'),
      country: Yup.string()
      .required('Please Select Country.'),
      city: Yup.string()
      .required('Please Select City.'),
  });

  const getBackgroundId = (data: any) => {
    if (data) {
      return '#3959F9'
    } else {
      return 'rgb(48, 48, 48)'
    }
  }

  const getColorId = (data: any) => {
    if (data) {
      return '#ffffff'
    } else {
      return '#8f92a1'
    }
  }

  const gerAvatarProfile = () => {
    if (profileImageURL !== "") {
      return <Avatar
        src={profileImageURL}
        alt="avatar" />
    } else {
      return <img src={plusRed}
        style={{ width: "24px", height: "24px" }}
      />
    }
  }

  const getCoverProfile = () => {
    if (coverImgURL !== "") {
      return <img src={profile?.attributes?.cover_image} alt="avatar" style={{ height: "100%", width: "100%" }}
      />
    } else {
      return <img src={plusRed}
        style={{ width: "24px", height: "24px" }} />
    }
  }

  const mapCountries = () => {
    return countries?.length !== 0 &&
      countries.map((genres: any, index) => {
        return (
          <Option value={genres?.label} style={{ backgroundColor: "white" }}
            key={index} id={genres?.id}>
            {genres?.label}</Option>
        )
      })
  }

  const showError = (touched: any, errors: any) => {
    if (touched && errors) {
      return <p style={{ color: '#aa3b3b' }}>{errors}</p>
    } else {
      return
    }
  }

  const returnSelectedGenre = (getFieldProps: any, genres: any, setFieldValue: any,val:any) => {
    let data: any = getFieldProps(val).value
    if (data.length == 0) {
      data[0] = genres?.attributes?.id
    }
    else {
      if (data.includes(genres?.attributes?.id)) {
        const filterdArray: any = arrayRemove(data, genres?.attributes?.id)
        data = filterdArray
      } else {
        data.push(genres?.attributes?.id)
      }
    }
    setFieldValue(val, data)
  }

  // Reusable CSS class
const commonStyle = "width_100 mt-3 justify-center-listener-creative check1";

  return (
    <Row className="ListenerProfile  width_100"  >
      <Grid container className="ForListener">

        <Grid item lg={6} md={6} xs={12} className="bgImg-welcome-create" >
          <img className="listener-creative-image1 check1" src={ListenerUpdateBackground} />
          <img className="image2" src={ListenerShade} />
        </Grid>
        <Grid item lg={6} md={6} xs={12} className="update-profile login_form " >

          {loading ? (<Row style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh" }}>
            <img src={nxzloader} style={{ width: "5%" }} />
          </Row>) : (<>
            <Formik
              initialValues={{
                generes: [],
                vibes: [],
                userName: currentUserName,
                country: '',
                city: ''
              }}
              validationSchema={ExploreSchema}
              onSubmit={(values: any) => doExplore(values)}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                isSubmitting,
                handleBlur,
                setFieldValue,
                initialValues, getFieldProps
              }) => (
                //@ts-ignore

                <Form>
                  <>
                    <Row justify="space-between" className="width_100 mt-4 inputs" style={{ alignItems: "end" }}>
                    <Col xl={10} lg={10} md={12} sm={24} xs={24} 
                    >
                        <div className={commonStyle}
                          style={{ display: "flex" }}
                        >
                          <div
                            className="upload-profile  " style={{ alignSelf: "end" }}>
                            <Upload
                              name="avatar"
                              listType="picture-card"
                              className="avatar_uploader"
                              showUploadList={false}
                              customRequest={(options: any) => uploadImage(options, "avatar")}
                              beforeUpload={beforeUpload}
                            >
                              {gerAvatarProfile()}
                              <img src={listenerimageupload} className="upload-cover-icon" />
                            </Upload>
                          </div>
                        
                        </div>
                        <div className="upload-font-stroke align-stroke-left">
                          UPLOAD
                        </div>
                        <div className="with-stroke align-stroke-left">
                          AVATAR
                        </div>
                      </Col>
                      <Col xl={14} lg={14} md={12} sm={24} xs={24} >
                        <div className=" width_100 mt-3"
                          style={{ display: "flex" }}
                        >
                          <div
                            className="upload-profile  " style={{ alignSelf: "end" }}></div>
                          <Upload
                            name="avatar"
                            listType="picture-card"
                            className="coverImg_uploader mt-1"
                            showUploadList={false}
                            customRequest={(options: any) => uploadImage(options, "coverImage")}

                            beforeUpload={beforeUpload}
                          >
                            {getCoverProfile()}
                            <img src={listenerimageupload} className="upload-cover-icon" />
                          </Upload>
                        </div>
                        <div className="upload-font-stroke align-stroke-center">
                          UPLOAD
                        </div>
                        <div className="with-stroke align-stroke-center">
                          COVER
                        </div>
                      </Col>
                    </Row>
                    <Row justify="space-between" className="width_100 inputs ">
                      <Col xl={12} lg={12} md={12} sm={24} xs={24} className="select_genera">
                        <div className="mt-3 mb_2 " style={{ display: "flex", flexDirection: "column" }} >
                          <Row className=" font-title-profile ">Select Country</Row>
                          <Select
                            className=" width_100 "
                            placeholder={<b>Choose Country </b>}
                            optionFilterProp="children"
                            clearIcon={false}
                            removeIcon
                            showArrow
                            showSearch={false}
                            onChange={(values) => {
                              setFieldValue('country', values)
                            }}
                            onBlur={handleBlur}
                            value={values.country}
                            //@ts-ignore
                            helperText={errors.country}
                            error={touched.country && errors.country ? true : false}
                            style={{
                              color: "white",
                              borderBottom: "1px solid #7a7e8a"
                            }}>
                            {mapCountries()}
                          </Select>
                      {showError(touched.country, errors.country)}
                        </div>
                      </Col>
                      <Col xl={12} lg={12} md={12} sm={24} xs={24} className="select_genera">
                        <div className=" mt-3 mb_2 ml-1-profile"
                          style={{ display: "flex", flexDirection: "column" }} >
                          <Row className=" font-title-profile " style={{ marginTop: "2%" }}>Select City</Row>
                          <input type="text" id="city" name="city" style={{
                            width: "100%",
                            padding: "0px 4px",
                            margin: "8px 0",
                            outline: 0,
                            border: "none",
                            borderBottom: " 1px solid #7a7e8a ",
                            backgroundColor: "transparent"
                          }}
                            value={values.city}
                            onChange={(e: any) => setFieldValue('city', e.target.value)}
                          />
                      {showError(touched.city, errors.city)}
                        </div>
                      </Col>
                    </Row>

                    <Col lg={24} className="inputs select_genera">
                      <Row className=" font-title-profile ">Select Genre</Row>
                      {showError(touched.generes, errors.generes)}
                      <Row>
                        {
                          genre?.length !== 0 &&
                          genre.map((genres: any, index) => {
                            return (
                              <button type="button" style={{
                                background: getBackgroundId(getFieldProps('generes').value.includes(genres?.attributes?.id)),
                                color: getColorId(getFieldProps('generes').value.includes(genres?.attributes?.id))

                              }}
                              onClick={() => returnSelectedGenre(getFieldProps,genres,setFieldValue,'generes')}
                                key={index} id={genres?.attributes?.id}>{genres?.attributes?.name.toUpperCase()}</button>
                            )
                          })}
                      </Row>
                    </Col>
                    <Row justify="space-between" className="width_100 mt-1 ">

                      <Col lg={24} className="inputs select_genera">
                        <Row className=" font-title-profile ">Select Vibe</Row>
                        {showError(touched.vibes, errors.vibes)}
                        <Row>
                          {
                            vibes?.length !== 0 &&
                            vibes.map((genres: any, index) => {
                              return (
                                <button type="button" style={{
                                  background: getBackgroundId(getFieldProps('vibes').value.includes(genres?.attributes?.id)),
                                  color: getColorId(getFieldProps('vibes').value.includes(genres?.attributes?.id))

                                }}
                                 
                                  onClick={() => returnSelectedGenre(getFieldProps,genres,setFieldValue,'vibes')}

                                  key={index} id={genres?.attributes?.id}>{genres?.attributes?.name.toUpperCase()}</button>
                              )
                            })}
                        </Row>

                      </Col>
                    </Row>

                    <Row justify="center"><Col xs={18} sm={17} md={15} lg={16} className="listener-profile-button mt-1 mb_2">
                      <button type="submit">
                        UPDATE PROFILE
                      </button>
                    </Col>

                    </Row>
                  </>
                </Form>
              )}
            </Formik>
          </>)}
        </Grid>
      </Grid>
      {/* </Col> */}
    </Row>
  )
};

export default ListenerProfile;
// Customizable Area End